import React from 'react';
import { Link } from 'gatsby';

export default function PrimaryTable(props) {
    const { table, uri, headers, publicProgramsBySector } = props;
    const goClasses = "mx-auto w-[88px] rounded-[4px] border-2 border-gray py-[5px] text-pTable text-gray700 hover:text-blue hover:border-blue"

    return (
        <div className="flex w-full flex-col px-[15px]">
            <div className="flex justify-center py-2 align-middle">
                <div className="overflow-clip shadow-tableShadow sm:rounded-lg">
                    <table>
                        <thead className="sticky top-0 bg-blue">
                        <tr>
                            {headers &&
                                headers.map((header, index) => {
                                    return (
                                        index >= 0 && (
                                            <th key={index} scope="col"
                                                className={'px-[17px] py-3 uppercase tracking-wider text-white  '}>
                                                <h3>{header.title}</h3>
                                            </th>
                                        )
                                    );
                                })}
                        </tr>
                        </thead>
                        <tbody>
                        {publicProgramsBySector.length < 1 &&
                            <tr className="bg-white">
                                <td className="text-sm px-[17px] py-3 font-robotoCondensed text-pTable text-gray900">
                                    Sorry, we weren't able to find a match
                                </td>
                            </tr>
                        }
                        {publicProgramsBySector.map((program, index) => (
                            <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-logoBG'}>
                                <td className="text-sm px-[17px] py-3 font-robotoCondensed  text-pTable text-gray900">
                                    <Link className="hover:text-blue" to={program.uri}>
                                        {program.name}
                                    </Link>
                                </td>

                                {program.hasApprenticeSchedule ? (
                                    <td className="text-sm text-gray-500   px-[17px] py-3 text-center">
                                        <Link to={`${program.uri}/Apprenticeship-Schedules`}>
                                            <div className={goClasses}>Schedule</div>
                                        </Link>
                                    </td>
                                ) : (
                                    <td className="text-sm text-gray-500 px-[17px] py-3 text-center">
                                        <div className="mx-auto w-[88px] py-[5px] text-pTable text-gray700"></div>
                                    </td>
                                )}

                                {program.hasFoundationSchedule ? (
                                    <td className="text-sm text-gray-500   px-[17px] py-3 text-center">
                                        <Link to={program.uri + '/Foundation-Schedules'}>
                                            <div className={goClasses}>Schedule</div>
                                        </Link>
                                    </td>
                                ) : (
                                    <td className="text-sm text-gray-500   px-[17px] py-3 text-center">
                                        <div className="mx-auto w-[88px] py-[5px] text-pTable text-gray700"></div>
                                    </td>
                                )}

                                {program.hasVocationalSchedule ? (
                                    <td className="text-sm text-gray-500   px-[17px] py-3 text-center">
                                        <Link to={program.uri + '/Vocational-Schedules'}>
                                            <div className={goClasses}>Schedule</div>
                                        </Link>
                                    </td>
                                ) : (
                                    <td className="text-sm text-gray-500   px-[17px] py-3 text-center">
                                        <div className="mx-auto w-[88px] py-[5px] text-pTable"></div>
                                    </td>
                                )}

                                {program.hasYouthSchedule ? (
                                    <td className="text-sm text-gray-500  px-[17px] py-3 text-center">
                                        <Link to={program.uri + '/Youth-Schedules'}>
                                            <div className={goClasses}>Schedule</div>
                                        </Link>
                                    </td>
                                ) : (
                                    <td className="text-sm text-gray-500   px-[17px] py-3 text-center">
                                        <div className="mx-auto w-[88px] py-[5px] text-pTable"></div>
                                    </td>
                                )}

                                {program.isRedSeal ? (
                                    <td className="text-sm text-gray-500   px-[17px] py-3 text-center">{program.isRedSeal &&
                                        <div>✔</div>}</td>
                                ) : (
                                    <td className="text-sm text-gray-500   px-[17px] py-3 text-center"></td>
                                )}
                                {program.isChallengeExam ? (
                                    <td className="text-sm text-gray-500   px-[17px] py-3 text-center">{program.isChallengeExam &&
                                        <div>✔</div>}</td>
                                ) : (
                                    <td className="text-sm text-gray-500   px-[17px] py-3 text-center"></td>
                                )}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

import React from 'react';
import Edges from '../components/Edges';

const TableSearch = (props) => {
    const { table, onChange, onSearch, onSectorSelect, sectors } = props;

    let programSectors = [];

    return (
        <Edges size="md">
            <div className="mb-[34px] flex flex-col justify-between md:flex-row ">
                <div className="px-[20px] lg:px-[0px]">
                    <select className="my-[5px] w-full rounded-[6px] border-2 border-gray py-[9px] px-[11px] font-robotoCondensed text-blue" onChange={onSectorSelect}>
                        <option value="" disabled>
                            Filter Programs by Sector
                        </option>
                        <option className="text-blue " value={'all'}>
                            All
                        </option>

                        {sectors &&
                            sectors.nodes.length &&
                            sectors.nodes.map((sector, index) => {
                                return (
                                    <option className="text-blue" key={index} value={sector.name}>
                                        {sector.name}
                                    </option>
                                );
                            })}

                        {/*{table.map((program, index) => {*/}
                        {/*    // todo: query for sectors*/}

                        {/*    // {*/}
                        {/*    //   allSector {*/}
                        {/*    //     nodes {*/}
                        {/*    //       id*/}
                        {/*    //       name*/}
                        {/*    //     }*/}
                        {/*    //   }*/}
                        {/*    // }*/}

                        {/*    const checkExists = programSectors.includes(program.sector.name);*/}

                        {/*    !checkExists && programSectors.push(program.sector.name);*/}

                        {/*    return (*/}
                        {/*        !checkExists && (*/}
                        {/*            <option className="text-blue" key={index} value={program.sector.name}>*/}
                        {/*                {program.sector.name}*/}
                        {/*            </option>*/}
                        {/*        )*/}
                        {/*    );*/}
                        {/*})}*/}
                    </select>
                </div>

                <div className="flex flex-col px-[20px] md:flex-row md:items-center ">
                    <input className="my-[5px] rounded-[6px] border-2 border-gray py-[9px] px-[11px] font-robotoCondensed text-gray900 md:w-[348px]" type="text" placeholder="Search Programs" onChange={onChange}></input>

                    <button onClick={onSearch} className="btn-primary md:ml-[10px]">
                        Search
                    </button>
                </div>
            </div>
        </Edges>
    );
};

export default TableSearch;

import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { matchSorter } from 'match-sorter';

import TradePrograms from '../components/tables/TradePrograms';
import TradeProgramsMobile from '../components/tables/TradeProgramsMobile';
import TableSearch from '../components/TableSearch';
import Seo from '../components/Seo';
import PageHeader from '../components/PageHeader';
import useSectorsQuery from '../hooks/useSectorsQuery';
import Edges from "../components/Edges";

const TradeProgramsPage = (props) => {
    const {
        data: { allPublicProgram },
    } = props;

    const sectors = useSectorsQuery();

    const tableHeaders = [{ title: 'Program Name' }, { title: 'Apprenticeship' }, { title: 'Foundation' }, { title: 'Technical' }, { title: 'Youth' }, { title: 'red seal' }, { title: 'Refresher' }];
    const mobileTableHeaders = [{ title: 'Program Name' }, { title: 'Apprenticeship' }, { title: 'Foundation' }, { title: 'Technical' }, { title: 'Youth' }, { title: 'red seal' }, { title: 'Refresher' }];

    const [search, setSearch] = useState(null);
    const [selectedSector, setSelectedSector] = useState(null);

    const handleSearch = () => {};

    const handleChangeSearch = (e) => {
        if (e.target.value) {
            setSearch(e.target.value);
        } else {
            setSearch(null);
        }
    };

    const handleSectorSelect = (e) => {
        if (e.target.value) {
            setSelectedSector(e.target.value);
        } else {
            setSelectedSector(null);
        }
    };

    const programSort = (a: typeof allPublicProgram.node, b: typeof allPublicProgram.node) => {
        return (a.name > b.name ? -1 : 1);
    };

    const filteredPublicPrograms = search
        ? matchSorter(allPublicProgram.nodes, search, {
              keys: ['name'],
              sorter: matchedItems => matchedItems.sort(programSort),
              threshold: matchSorter.rankings.WORD_STARTS_WITH
          })
        : allPublicProgram.nodes;

    const publicProgramsBySector =
        selectedSector === 'all' || !selectedSector
            ? filteredPublicPrograms
            : matchSorter(filteredPublicPrograms, selectedSector, {
                  keys: ['sector.name'],
                  sorter: matchedItems => matchedItems.sort(programSort),
              });

    const pageTitle = 'All Available Trade Programs';

    return (
        <>
            <Seo title={pageTitle} />
            <PageHeader title={pageTitle} breadcrumbs={[{ label: pageTitle, url: '/tradeprograms' }]} />

            <div>
                <TableSearch table={allPublicProgram.nodes} onChange={handleChangeSearch} onSearch={handleSearch} onSectorSelect={handleSectorSelect} sectors={sectors} />
                <div className="hidden sm:block">
                    <TradePrograms publicProgramsBySector={publicProgramsBySector} headers={tableHeaders} />
                </div>
                <div className="block sm:hidden">
                    <TradeProgramsMobile publicProgramsBySector={publicProgramsBySector} headers={mobileTableHeaders} />
                </div>
            </div>
        </>
    );
};

export default TradeProgramsPage;

export const pageQuery = graphql`
    query TradeProgramsQuery {
        allPublicProgram(sort: {fields: name}) {
            nodes {
                uri
                name
                isRedSeal
                redSealURL
                workBCURL
                slug
                slug
                itaurl
                hasApprenticeSchedule
                hasFoundationSchedule
                hasVocationalSchedule
                hasYouthSchedule
                isChallengeExam
                sector {
                    slug
                    name
                }
            }
        }
    }
`;

/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { Link } from 'gatsby';

export default function MobileTable(props) {
    const { table, uri, headers, publicProgramsBySector } = props;

    const checkProgramType = (table, tableIdx, type) => {
        const filterType = table?.publicProgramTypeIds?.filter((element) => element === type);
        return filterType?.length > 0;
    };

    return (
        <div className="flex] flex-col px-[20px]">
            <div className="-my-2 mb-[32px]">
                <div className="py-2 align-middle">
                    <div className="overflow-x-auto rounded-lg shadow-tableShadow ">
                        <table className="block md:hidden">
                            {publicProgramsBySector.map((program, index) => (
                                <tbody key={index} className={index % 2 === 0 ? 'flex flex-row bg-blue' : 'flex flex-row bg-darkBlue'}>
                                    <tr>
                                        {headers &&
                                            headers.map((header, index) => {
                                                return (
                                                    index >= 0 && (
                                                        <th
                                                            key={index}
                                                            scope="col"
                                                            className={
                                                                uri === '/tradeprograms' && index % 2 === 0
                                                                    ? 'flex h-[47px] flex-col px-2 py-3 uppercase tracking-wider text-white'
                                                                    : 'flex h-[47px] flex-col px-2 py-3 text-right uppercase tracking-wider text-white '
                                                            }
                                                        >
                                                            <h3 className="my-auto">{header.title}</h3>
                                                        </th>
                                                    )
                                                );
                                            })}
                                    </tr>

                                    <tr key={index} className={index % 2 === 0 ? 'flex flex-1 flex-col bg-white' : 'flex flex-1 flex-col bg-logoBG'}>
                                        <td className="text-sm flex h-[47px] items-center px-3 py-4 font-robotoCondensed text-pTable text-gray900">
                                            <Link className="hover:text-blue" to={program.uri}>
                                                {program.name}
                                            </Link>
                                        </td>

                                        {program.hasApprenticeSchedule ? (
                                            <td className="text-sm text-gray-500 flex h-[47px] items-center px-3 py-4">
                                                <Link to={program.uri + '/Apprenticeship-Schedules'}>
                                                    <div className="w-[88px] rounded-[4px] border-2 border-gray py-[5px] text-pTable text-gray700 ">
                                                        <div className="text-center">Schedule</div>
                                                    </div>
                                                </Link>
                                            </td>
                                        ) : (
                                            <td className="text-sm text-gray-500 h-[47px] px-6 py-4 text-center">
                                                <div className="mx-auto w-[88px] py-[5px] text-pTable text-gray700"></div>
                                            </td>
                                        )}

                                        {program.hasFoundationSchedule ? (
                                            <td className="text-sm text-gray-500 flex h-[47px] items-center px-3 py-4">
                                                <Link to={program.uri + '/Foundation-Schedules'}>
                                                    <div className="w-[88px] rounded-[4px] border-2 border-gray py-[5px] text-pTable text-gray700 ">
                                                        <div className="text-center">Schedule</div>
                                                    </div>
                                                </Link>
                                            </td>
                                        ) : (
                                            <td className="text-sm text-gray-500 h-[47px] px-6 py-4 text-center">
                                                <div className="mx-auto w-[88px] py-[5px] text-pTable text-gray700"></div>
                                            </td>
                                        )}

                                        {program.hasVocationalSchedule ? (
                                            <td className="text-sm text-gray-500 flex h-[47px] items-center px-3 py-4">
                                                <Link to={program.uri + '/Vocational-Schedules'}>
                                                    <div className="w-[88px] rounded-[4px] border-2 border-gray py-[5px] text-pTable text-gray700">
                                                        <div className="text-center">Schedule</div>
                                                    </div>
                                                </Link>
                                            </td>
                                        ) : (
                                            <td className="text-sm text-gray-500 h-[47px] px-6 py-4 text-center">
                                                <div className="mx-auto w-[88px] py-[5px] text-pTable"></div>
                                            </td>
                                        )}

                                        {program.hasYouthSchedule ? (
                                            <td className="text-sm text-gray-500 flex h-[47px] items-center px-3 py-4">
                                                <Link to={program.uri + '/Youth-Schedules'}>
                                                    <div className="w-[88px] rounded-[4px] border-2 border-gray py-[5px] text-pTable text-gray700">
                                                        <div className="text-center">Schedule</div>
                                                    </div>
                                                </Link>
                                            </td>
                                        ) : (
                                            <td className="text-sm text-gray-500 h-[47px] px-6 py-4 text-center">
                                                <div className="mx-auto w-[88px] py-[5px] text-pTable"></div>
                                            </td>
                                        )}

                                        {program.isRedSeal ? (
                                            <td className="text-sm text-gray-500 flex h-[47px] items-center px-3 py-3">{program.isRedSeal && <div className="ml-[30px] md:ml-[0px]">✔</div>}</td>
                                        ) : (
                                            <td className="text-sm text-gray-500 h-[47px] px-6 py-4 text-center">
                                                <div className="mx-auto w-[88px] py-[5px] text-pTable"></div>
                                            </td>
                                        )}

                                        {program.isChallengeExam ? (
                                            <td className="text-sm text-gray-500 flex h-[47px] items-center px-3 py-3">{program.isChallengeExam && <div className="ml-[30px] md:ml-[0px]">✔</div>}</td>
                                        ) : (
                                            <td className="text-sm text-gray-500 h-[47px] px-6 py-4 text-center">
                                                <div className="mx-auto w-[88px] py-[5px] text-pTable"></div>
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

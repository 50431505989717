import { useStaticQuery, graphql } from 'gatsby';

const useSectorsQuery = () => {
    const { allSector } = useStaticQuery(graphql`
        {
            allSector(sort: { fields: name }) {
                nodes {
                    id
                    databaseId
                    name
                }
            }
        }
    `);

    return allSector;
};

export default useSectorsQuery;
